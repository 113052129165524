import React, { useRef, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { CloudUpload } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import useAppContext from "../context/useAppContext"
import { uploadFile, FileUploadResponse } from "../service/fileService"
import FileUploadDialog from './FileUploadDialog'

interface FileUploadButtonProps {
  onUploadComplete?: (fileResponse: FileUploadResponse) => void;
  onError?: (error: any) => void;
  buttonText?: string | HTMLElement;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  onUploadComplete,
  onError,
  buttonText = 'Upload or drop a file',
  variant = 'outlined',
  color = 'primary'
}) => {
  const { guild } = useAppContext()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<FileUploadResponse | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files || files.length === 0) return
    
    if (!guild?.id) {
      enqueueSnackbar('Please select a server first', { variant: 'error',  persist: false })
      return
    }
    
    setIsUploading(true)
    
    try {
      const response = await uploadFile(guild.id, files[0])
      setUploadedFile(response)
      setDialogOpen(true)
      if (onUploadComplete) {
        onUploadComplete(response)
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      if (onError) {
        onError(error)
      } else {
        enqueueSnackbar('Failed to upload file', { variant: 'error', persist: false })
      }
    } finally {
      setIsUploading(false)
      // Clear the input so the same file can be uploaded again
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleDeleteComplete = () => {
    setUploadedFile(null)
  }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <Button
        size="small"
        variant={variant}
        color={color}
        startIcon={isUploading ? <CircularProgress size={20} color="inherit" /> : <CloudUpload />}
        onClick={handleClick}
        disabled={isUploading}
      >
        {isUploading ? 'Uploading...' : buttonText}
      </Button>

      <FileUploadDialog
        uploadedFile={uploadedFile}
        open={dialogOpen}
        onClose={handleDialogClose}
        onDeleteComplete={handleDeleteComplete}
      />
    </>
  )
}

export default FileUploadButton
