import React, { useEffect, useState } from 'react'
import { 
  Paper, 
  Typography, 
  Button, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions
} from '@mui/material'
import { Delete, ContentCopy, ZoomIn } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import useAppContext from "../../context/useAppContext"
import { deleteFile, getFileUrl, listFiles, FileAttachment } from "../../service/fileService"
import FileUploadButton from '../FileUploadButton'

export const FileAttachments = () => {
  const { guild } = useAppContext()
  const [files, setFiles] = useState<FileAttachment[]>([])
  const [loading, setLoading] = useState(false)
  const [previewFile, setPreviewFile] = useState<FileAttachment | null>(null)
  const [previewOpen, setPreviewOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const fetchFiles = async () => {
    if (!guild?.id) return
    
    setLoading(true)
    try {
      const fileList = await listFiles(guild.id)
      setFiles(fileList)
    } catch (error) {
      console.error('Error fetching files:', error)
      enqueueSnackbar('Failed to load files', { variant: 'error', persist: false })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchFiles()
  }, [guild?.id])

  const handleDelete = async (fileId: string) => {
    if (!guild?.id) return
    
    if (!window.confirm('Are you sure you want to delete this file?')) {
      return
    }
    
    try {
      await deleteFile(guild.id, fileId)
      enqueueSnackbar('File deleted successfully', { variant: 'success', persist: false })
      setFiles(files.filter(file => file.id !== fileId))
    } catch (error) {
      console.error('Error deleting file:', error)
      enqueueSnackbar('Failed to delete file', { variant: 'error', persist: false })
    }
  }

  const copyAttachmentTag = (fileId: string, fileName: string) => {
    const tag = `<attachment id="${fileId}" alt="${fileName}">`
    navigator.clipboard.writeText(tag)
    enqueueSnackbar('Attachment tag copied to clipboard', { variant: 'success', persist: false })
  }

  const formatFileSize = (bytes: number): string => {
    if (bytes < 1024) return `${bytes} bytes`
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`
    return `${(bytes / (1024 * 1024)).toFixed(1)} MB`
  }

  const formatDate = (dateString: string): string => {
    if (!dateString) return 'Unknown'
    const date = new Date(dateString)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }

  const isImage = (mimeType: string): boolean => {
    return mimeType.startsWith('image/')
  }
  
  const handleOpenPreview = (file: FileAttachment) => {
    setPreviewFile(file)
    setPreviewOpen(true)
  }
  
  const handleClosePreview = () => {
    setPreviewOpen(false)
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <Typography variant="h6">File Attachments</Typography>
        <FileUploadButton 
          onUploadComplete={() => fetchFiles()}
          variant="contained"
          onError={(error) => {
            if (error?.response?.data?.message) {
              enqueueSnackbar(error.response.data.message, { variant: 'error', persist: true })
            } else {
              enqueueSnackbar('Failed to upload file', { variant: 'error', persist: false })
            }
          }}
        />
      </div>
      
      <Typography variant="body2" className="mb-4">
        Upload files to use in FAQs, instructions, and documents. Copy the tag and paste it into your content.
        <br/>
        You can change the "alt" text to better describe the file for the AI.
      </Typography>
      
      {files.length > 0 && (
        <Typography variant="body2" className="mb-4 text-gray-400 text-left">
          {files.length} {files.length === 1 ? 'file' : 'files'}
        </Typography>
      )}
      
      {loading ? (
        <div className="flex justify-center my-8">
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper} sx={{ bgcolor: '#141821' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Preview</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Date Added</TableCell>
                <TableCell>Tag</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No files uploaded yet. Use the upload button to add files.
                  </TableCell>
                </TableRow>
              ) : (
                files.map(file => (
                  <TableRow key={file.id}>
                    <TableCell style={{ width: '100px' }}>
                      {isImage(file.mime_type) && guild?.id ? (
                        <div className="cursor-pointer relative group" onClick={() => handleOpenPreview(file)}>
                          <img 
                            src={getFileUrl(guild.id, file.id)} 
                            alt={file.file_name}
                            style={{ maxWidth: '80px', maxHeight: '80px', objectFit: 'contain' }}
                          />
                          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                            <ZoomIn className="text-white" />
                          </div>
                        </div>
                      ) : (
                        <div className="text-gray-400 text-xs">
                          {file.mime_type.split('/')[1]?.toUpperCase() || file.mime_type}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{file.file_name}</TableCell>
                    <TableCell>{formatFileSize(file.file_size_bytes)}</TableCell>
                    <TableCell>{formatDate(file.created_at)}</TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        <code className="text-xs text-gray-300 mr-2">
                          {`<attachment id="${file.id}" alt="${file.file_name}">`}
                        </code>
                        <Tooltip title="Copy tag">
                          <IconButton 
                            size="small" 
                            onClick={() => copyAttachmentTag(file.id, file.file_name)}
                          >
                            <ContentCopy fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(file.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      {/* Image Preview Dialog */}
      <Dialog 
        open={previewOpen} 
        onClose={handleClosePreview} 
        maxWidth="lg"
        PaperProps={{
          sx: {
            bgcolor: '#0A0D14',
            borderRadius: 2,
          }
        }}
      >
        <DialogContent>
          {previewFile && guild?.id && isImage(previewFile.mime_type) && (
            <img
              src={getFileUrl(guild.id, previewFile.id)}
              alt={previewFile.file_name}
              style={{ 
                maxWidth: '100%', 
                maxHeight: '80vh', 
                objectFit: 'contain',
                display: 'block',
                margin: '0 auto'
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
