import React from 'react'
import { Dialog, DialogContent, DialogActions, Button, IconButton, Typography, Box, Paper } from '@mui/material'
import { Close, Delete, ContentCopy } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import useAppContext from "../context/useAppContext"
import { deleteFile, getFileUrl, FileUploadResponse } from "../service/fileService"

interface FileUploadDialogProps {
  uploadedFile: FileUploadResponse | null;
  open: boolean;
  onClose: () => void;
  onDeleteComplete?: () => void;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({
  uploadedFile,
  open,
  onClose,
  onDeleteComplete
}) => {
  const { guild } = useAppContext()
  const { enqueueSnackbar } = useSnackbar()

  const handleDeleteFile = async () => {
    if (!guild?.id || !uploadedFile) return

    try {
      await deleteFile(guild.id, uploadedFile.file_id)
      enqueueSnackbar('File deleted successfully', { variant: 'success' })
      onClose()
      if (onDeleteComplete) {
        onDeleteComplete()
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      enqueueSnackbar('Failed to delete file', { variant: 'error' })
    }
  }

  const copyToClipboard = () => {
    if (uploadedFile) {
      navigator.clipboard.writeText(uploadedFile.attachment_tag)
      enqueueSnackbar('Attachment tag copied to clipboard', { variant: 'success' })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: '#0A0D14',
          borderRadius: 2,
        }
      }}
    >
      <DialogContent>
        {uploadedFile && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">File Uploaded Successfully</Typography>
              <IconButton onClick={onClose} sx={{ color: 'white' }}>
                <Close/>
              </IconButton>
            </Box>

            <Typography variant="body1">
              File: {uploadedFile.file_name}
            </Typography>

            {uploadedFile.mime_type.startsWith('image/') && guild?.id && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <img
                  src={getFileUrl(guild.id, uploadedFile.file_id)}
                  alt={uploadedFile.file_name}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '300px',
                    objectFit: 'contain',
                    borderRadius: '4px'
                  }}
                />
              </Box>
            )}

            <Paper sx={{ bgcolor: '#1A1D24', p: 2, borderRadius: 1 }}>
              <Typography variant="body2" sx={{ fontFamily: 'monospace', overflowWrap: 'break-word' }}>
                {uploadedFile.attachment_tag}
              </Typography>
            </Paper>

            <p className="text-md text-green-200">
              Copy the above tag and paste it anywhere in your instructions, FAQ answers, documents or any message the bot sends.
            </p>
            <p className="text-sm text-gray-300">
              Whenever the bot thinks it's useful, it will send the file as an attachment in its response.
              You can change the "alt" to describe the file better.
            </p>
            <Button
              className="w-fit"
              startIcon={<ContentCopy/>}
              variant="outlined"
              onClick={copyToClipboard}
            >
              Copy Attachment Tag
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
        <Button
          startIcon={<Delete/>}
          color="error"
          onClick={handleDeleteFile}
        >
          Delete File
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FileUploadDialog
