import React from "react"
import { Divider, Paper } from "@mui/material"
import { useViewport } from "../../util/hooks/useViewport"
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import EditRoadIcon from '@mui/icons-material/EditRoad'
import StorageIcon from '@mui/icons-material/Storage'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import AttachmentIcon from '@mui/icons-material/Attachment'
import HistorySearchSettings from "../settings/HistorySearchSettings"
import { ExtractFAQs } from "../settings/ExtractFAQs"
import { MassEditFAQs } from "../settings/MassEditFAQs"
import { FileAttachments } from "../settings/FileAttachments"
import { useNavigate, useParams } from 'react-router-dom'
import { TabProps } from "@mui/material/Tab/Tab"
import PanelPage from "./PanelPage"
import { DataStorage } from "../settings/DataStorage"
import useAppContext from "../../context/useAppContext"
import { ExportImport } from "../settings/ExportImport"
import { ErrorBoundary } from "../common/ErrorBoundary"

export const DEFAULT_SETTINGS_TAB = 'export-import'
const SETTINGS_PAGE = '/other-settings'


const MoreSettingsPage = () => {
  const { config, guild } = useAppContext()
  const navigate = useNavigate()
  const { settingsTab = DEFAULT_SETTINGS_TAB } = useParams()

  const { isMobile } = useViewport()

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    navigate(`${SETTINGS_PAGE}/${newValue}`, { replace: true })
  }

  if (!config || !guild) {
    return null
  }

  return (
    <PanelPage>
      <Divider className="bg-gray-800"/>
      <div className={"text-center mt-0 mb-10" + (isMobile ? "" : " mx-4 flex")} style={{ padding: '0' }}>
        <TabContext value={settingsTab}>
          <div className="w-full max-w-[1200px]">
            <div className="mt-4 mb-12 w-full">
              <TabList
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                orientation={isMobile ? "vertical" : "horizontal"}
              >
                <SimpleTab value="export-import" label="Export & Import" icon={<ImportExportIcon/>}/>
                <SimpleTab value="server-data" label="Your Server Data" icon={<StorageIcon/>}/>
                <SimpleTab value="history-search" label="Answer from history" icon={<ManageSearchIcon/>}/>
                <SimpleTab value="import-faqs" label="Import FAQs" icon={<AutoFixHighIcon/>}/>
                <SimpleTab value="mass-edit-faqs" label="Mass Edit FAQs" icon={<EditRoadIcon/>}/>
                <SimpleTab value="file-attachments" label="File Attachments" icon={<AttachmentIcon/>}/>
              </TabList>
            </div>
            <Paper className="mx-auto p-4">
              <TabPanel value="import-faqs">
                <ErrorBoundary>
                  <ExtractFAQs/>
                </ErrorBoundary>
              </TabPanel>
              <TabPanel value="history-search">
                <ErrorBoundary>
                  <HistorySearchSettings/>
                </ErrorBoundary>
              </TabPanel>
              <TabPanel value="mass-edit-faqs">
                <ErrorBoundary>
                  <MassEditFAQs/>
                </ErrorBoundary>
              </TabPanel>
              <TabPanel value="server-data">
                <ErrorBoundary>
                  <DataStorage/>
                </ErrorBoundary>
              </TabPanel>
              <TabPanel value="export-import">
                <ErrorBoundary>
                  <ExportImport/>
                </ErrorBoundary>
              </TabPanel>
              <TabPanel value="file-attachments">
                <ErrorBoundary>
                  <FileAttachments/>
                </ErrorBoundary>
              </TabPanel>
            </Paper>
          </div>
        </TabContext>
      </div>
    </PanelPage>
  )

}

interface SimpleTabProps extends TabProps {
}

const SimpleTab = (props: SimpleTabProps) => {
  return (
    <Tab
      className="self-start"
      iconPosition="start"
      {...props}
    />
  )
}


export default MoreSettingsPage
