import { useEffect, useState } from "react"
import { DocumentsResponse, fetchDocuments, getPercentageProcessed, pollDocumentProgress, WalluDocument } from "../../service/documentService"
import { TaskPollResponse } from "../../service/faqService"
import useAppContext from "../../context/useAppContext"

function useDocumentProgress(documentsCallback?: (docs: DocumentsResponse) => any) {

  const { guild } = useAppContext()
  const [importProgress, setImportProgress] = useState<{ [documentId: string]: TaskPollResponse }>({})
  const [percentageDocumentsProcessing, setPercentageDocumentsProcessing] = useState<number>(0)
  const [allDocuments, setAllDocuments] = useState<DocumentsResponse | undefined>(undefined)

  useEffect(() => {
    const checkDocumentProgress = async () => {
      const res = await fetchDocuments(guild?.id)
      documentsCallback?.(res)
      setAllDocuments(res)
      const progress = await getPercentageProcessed(guild?.id)
      setPercentageDocumentsProcessing(progress.processingPercentage)
      await Promise.all(res.documents.map(async doc => {
        const documentId = doc.id as string
        const taskId = doc.meta.scrape_task_id
        if (taskId) {
          try {
            const result = await pollDocumentProgress(taskId)
            setImportProgress(prev => ({
              ...prev,
              [documentId]: result
            }))
          } catch (err) {
            console.error(err)
            window.alert("Error occurred: " + err)
          }
        } else if (doc.read_only && !doc.content) {
          setImportProgress(prev => ({
            ...prev,
            [documentId]: {
              ready: false,
              state: "PENDING",
              current: -1, // unknown %
              total: 1
            }
          }))
        }
      }))
    }
    checkDocumentProgress().then()
    const interval = setInterval(checkDocumentProgress, 10_000)
    return () => clearInterval(interval)
  }, [guild])

  const documents = allDocuments?.documents || []

  const progressForDocument = (document: WalluDocument) => {
    if (document.meta.fully_indexed_at) {
      return percentageDocumentsProcessing
    }
    const progress = importProgress[document.id]
    // after scrape_task_id is removed from meta, it will be added to the percentageDocumentsProcessing in the backend (cache table)
    if (progress?.ready || document.meta.scrape_task_id || !progress) {
      return percentageDocumentsProcessing
    }
    return (progress.current / progress.total) * 0.25
  }

  const totalProgress = documents.length > 0 ? documents.map(progressForDocument).reduce((a, b) => a + b) / documents.length : 1

  return {
    importProgress,
    totalProgress: totalProgress
  }
}

export default useDocumentProgress
