import React, { useState, useRef } from 'react'
import { Paper, Typography } from '@mui/material'
import { CloudUpload } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import useAppContext from "../context/useAppContext"
import { uploadFile, FileUploadResponse } from "../service/fileService"
import FileUploadDialog from './FileUploadDialog'

interface FileDropZoneProps {
  children: React.ReactNode;
}

export const FileDropZone: React.FC<FileDropZoneProps> = ({ children }) => {
  const { guild } = useAppContext()
  const [isDragging, setIsDragging] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<FileUploadResponse | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dragCounter = useRef(0)

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter.current += 1
    if (dragCounter.current === 1) {
      setIsDragging(true)
    }
  }

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter.current -= 1
    if (dragCounter.current === 0) {
      setIsDragging(false)
    }
  }

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
    dragCounter.current = 0

    const files = e.dataTransfer.files
    if (files.length > 0) {
      await handleFileUpload(files[0])
    }
  }

  const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      await handleFileUpload(e.target.files[0])
    }
  }

  const handleFileUpload = async (file: File) => {
    if (!guild?.id) {
      enqueueSnackbar('Please select a server first', { variant: 'error' })
      return
    }

    setIsUploading(true)

    try {
      const response = await uploadFile(guild.id, file)
      setUploadedFile(response)
      setDialogOpen(true)
    } catch (error) {
      console.error('Error uploading file:', error)
      enqueueSnackbar('Failed to upload file', { variant: 'error' })
    } finally {
      setIsUploading(false)
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleDeleteComplete = () => {
    setUploadedFile(null)
  }

  return (
    <div
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      {children}

      {isDragging && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(25, 118, 210, 0.1)',
            border: '2px dashed #1976d2',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(18, 18, 18, 0.9)',
              borderRadius: 2
            }}
          >
            <CloudUpload sx={{ fontSize: 64, color: '#1976d2', mb: 2 }}/>
            <Typography variant="h6" sx={{ color: '#fff' }}>
              Drop file here to upload
            </Typography>
          </Paper>
        </div>
      )}

      <FileUploadDialog
        uploadedFile={uploadedFile}
        open={dialogOpen}
        onClose={handleDialogClose}
        onDeleteComplete={handleDeleteComplete}
      />
    </div>
  )
}

export default FileDropZone
