import client, { BASE_URL } from "../config/axios"

export interface FileUploadResponse {
  file_id: string;
  file_name: string;
  mime_type: string;
  file_size_bytes: number;
  attachment_tag: string;
}

export interface FileAttachment {
  id: string;
  file_name: string;
  mime_type: string;
  file_size_bytes: number;
  created_at: string;
}

export async function uploadFile(guildId: string, file: File): Promise<FileUploadResponse> {
  const formData = new FormData()
  formData.append('file', file)

  const response = await client.post(`/file-upload/${guildId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  
  return response.data
}

export async function deleteFile(guildId: string, fileId: string): Promise<void> {
  await client.delete(`/file-upload/${guildId}/${fileId}`)
}

export function getFileUrl(guildId: string, fileId: string): string {
  return `${BASE_URL}/file-upload/${guildId}/${fileId}`
}

export async function listFiles(guildId: string): Promise<FileAttachment[]> {
  const response = await client.get(`/file-upload/${guildId}`)
  return response.data
}